import React, { useEffect, useState } from 'react';
import './leaders-stats.css';

let leaders = []

const leaderMappings = {
    "PowerActionPrototypeUnitTarget": "Unit Target",
    "PowerActionPrototypeNoTarget": "No Target",
};

const LeadersStats = () => {
    const [message, setMessage] = useState('Loading leaders...');
    const [leaderList, setLeaderList] = useState(leaders);

    useEffect(() => {
        const url = `${process.env.REACT_APP_ASSETS_URL}/assets/leaders.json`
        console.log(url)
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((json) => {
                leaders = Object.values(json).sort((l1, l2) => l1.name.localeCompare(l2.name));
                setMessage(null);
                setLeaderList(leaders);
            })
            .catch((error) => {
                setMessage('Failed to load leaders');
                console.error("Error fetching or processing JSON:", error.message);
            });
    }, []);

    
    return (
        <div className="leaders-page">
            <h1>Leaders</h1>
            {message ? (
                <p>{message}</p>
            ) : (
                <div>
                    <ul className="leader-list">
                        {leaderList.map((leader) => {
                            return (
                                <li key={ leader.name }>
                                <article className="leader">
                                    <header className="leader-header">
                                        <h3 className="leader-title">{ leader.name }</h3>
                                    </header>
                                    <main className="leader-main">
                                        <figure className="leader-icon">
                                            <img src={ `${process.env.REACT_APP_ASSETS_URL}/${leader.icon}` } alt={ leader.name } />
                                        </figure>
                                        <section className="leader-powers">

                                            {leader.powers.map((power) => {
                                                return (
                                                    <section className="power">
                                                        <section className="power-header">
                                                            <img className="power-icon" src={ `${process.env.REACT_APP_ASSETS_URL}/${power.icon}` } alt={ power.name } />
                                                            <div className="power-name">{ power.name }</div>
                                                        </section>
                                                        <section className="power-info">
                                                            <ul>
                                                                <li key="action">
                                                                    <div className="key">Type</div>
                                                                    <div className="value">{ leaderMappings[power.action?.tag] ?? 'Passive' }</div>
                                                                </li>
                                                            </ul>
                                                        </section>
                                                        <section className="power-description">
                                                            <p dangerouslySetInnerHTML={{ __html: power.description.replace(/\r?\n/g, '<br>') }}/>
                                                        </section>
                                                        <section className="power-values">
                                                            <ul>
                                                                {Object.entries(power.values).map(([key, value]) => (
                                                                    <li key={key}>
                                                                        <div className="key">{ value.title }</div>
                                                                        <div className="value" dangerouslySetInnerHTML={{ __html: value.description }}></div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </section>
                                                        <section className="power-milestones">
                                                            {Object.values(power.milestones).map((milestone) => {
                                                                return (
                                                                    <div className="power-milestone">
                                                                        <div className="power-milestone-title">{ milestone.title }</div>
                                                                        <div className="power-milestone-description" dangerouslySetInnerHTML={{ __html: milestone.description?.replace(/\r?\n/g, '<br>') }}/>
                                                                    </div>
                                                                )
                                                            })}
                                                        </section>
                                                        <section className="power-stats">
                                                            {power.action?.val?.cooldown ? (
                                                                <div className="power-stats-value">
                                                                    <img src="cooldown.png" alt="Cooldown" title="Cooldown"/>
                                                                    <span>{power.action.val.cooldown}</span>
                                                                </div>
                                                            ) : "" }
                                                        </section>
                                                    </section>
                                                )
                                            })}
                                        </section>
                                    </main>
                                </article>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default LeadersStats;
